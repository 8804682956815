<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row" v-if="activeTab == 0">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Необходима ли пациентке коррекция гиполипидемической терапии? Если
              да, то какой препарат предпочтительнее?
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs[0] != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
                v-if="radioTabs[0] != null"
              >
                <div
                  v-if="radioTabs[0] == 'test21_1_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/green_check2.png')
                    "
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs[0] !== 'test21_1_3'"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/red_warning.png')
                    "
                  />
                  Неверный ответ!
                </div>
                <div>
                  <div class="mb-4">
                    <video
                      src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/8.mp4"
                      :poster="
                        require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                      "
                      controls
                    />
                  </div>
                  <div class="text-2 mb-4">
                    <div class="mb-4">
                      <b
                        >Оценка риска сердечно-сосудистых осложнений<sup
                          >7,10</sup
                        ></b
                      >
                    </div>
                    <div class="full-screen mb-4 mx-auto">
                      <div
                        class="full-screen__trigger"
                        :data-coolbox="
                          require('@/assets/img/interactive-clinical-case/step20__table1.jpg')
                        "
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="8"
                            fill="#830051"
                          ></rect>
                          <path
                            d="M23 11H29V17"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M17 29H11V23"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M29 11L22 18"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11 29L18 22"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>

                      <img
                        :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table1.jpg')} 2x`"
                      />
                    </div>
                    <div class="mb-2">
                      <b
                        >Выбор целевых значений ХС ЛНП с целью профилактики
                        ССО<sup>7,10</sup></b
                      >
                    </div>
                    <div class="full-screen mb-4 mx-auto">
                      <div
                        class="full-screen__trigger"
                        :data-coolbox="
                          require('@/assets/img/interactive-clinical-case/step20__table2.jpg')
                        "
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="8"
                            fill="#830051"
                          ></rect>
                          <path
                            d="M23 11H29V17"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M17 29H11V23"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M29 11L22 18"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11 29L18 22"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>

                      <img
                        :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table2.jpg')} 3x`"
                      />
                    </div>
                    <div class="mb-4">
                      <i>
                        Комментарий к клиническому случаю: риск ССО у пациентки
                        очень высокий, целевой уровень ХС ЛНП &lt;1,4 ммоль/л,
                        ТГ &lt;1,7 ммоль/л
                      </i>
                    </div>
                    <div class="full-screen mb-4 mx-auto">
                      <div
                        class="full-screen__trigger"
                        :data-coolbox="
                          require('@/assets/img/interactive-clinical-case/step20__table3.jpg')
                        "
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="8"
                            fill="#830051"
                          ></rect>
                          <path
                            d="M23 11H29V17"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M17 29H11V23"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M29 11L22 18"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11 29L18 22"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>

                      <img
                        :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table3.jpg')} 3x`"
                      />
                    </div>
                    <ul class="mb-4">
                      <li>
                        Всем пациентам с дислипидемией рекомендована терапия
                        <b>статином</b> в дозах, необходимых для достижения
                        целевого уровня ХС ЛНП. При непереносимости
                        высокоинтенсивной терапии статинов следует рассмотреть
                        снижение дозы препарата с последующей переоценкой
                        переносимости<sup>7</sup>.
                      </li>
                    </ul>
                    <div class="mb-2">
                      Розувастатин (Крестор) является одним из самых эффективных
                      и мощных препаратов в классе ингибиторов
                      ГМГ-КоА-редуктазой. Это обусловлено полярной
                      метансульфонамидной группой, которая обеспечивает
                      уникальное взаимодействие с ГМГ-КоА-редуктазой, образуя
                      наибольшее количество связывающих доменов<sup>11</sup>.
                    </div>
                    <div class="mb-2">
                      Период полувыведения розувастатина составляет около 19
                      часов и является самым продолжительным среди препаратов
                      своего класса12. Такие факторы, как возраст, пол, время
                      приема препарата или пищи не оказывают значимого
                      клинического влияния на фармакокинетические свойства
                      розувастатина13,14. Абсолютная биодоступность
                      розувастатина примерно равно 20% и сопоставима с таковыми
                      у аторвастатина, флувастатина, правастатина и выше, чем у
                      ловастатина и симвастатина (&lt; 5%)15. Гидрофильность
                      розувастатина, в отличие от остальных статинов, являющихся
                      липофильными, определяет низкую миотоксичность препарата в
                      связи с незначительным проникновением во внепеченочные
                      ткани<sup>16</sup>.
                    </div>
                    <div class="mb-2">
                      В Российской Федерации Розувастатин (Крестор)
                      зарегистрирован и применяется в суточной дозе 5-40
                      мг<sup>17</sup>.
                    </div>
                    <div class="mb-2">
                      Процент снижения уровня ОХ, ХС ЛНП, ХС-не-ЛПВП под
                      влиянием розувастатина варьирует в зависимости от
                      применяемой дозы препарата<sup>17</sup>.
                    </div>
                    <div class="mb-4">
                      Внедрение в широкую клиническую практику розувастатина
                      основано на результатах исследований, выполненных в рамках
                      программы <b>GALAXY</b><sup>18-22</sup>.
                    </div>
                    <ul class="mb-8">
                      <li>
                        Пациентам с непереносимостью любой дозы статина
                        рекомендован прием <b>эзетимиба</b><sup>7</sup>.
                      </li>
                      <li>
                        Пациентам, не достигшим целевого уровня ХС ЛНП на фоне
                        максимально переносимых доз статинов, следует
                        рассмотреть возможность комбинированной терапии, в том
                        числе <b>статин с эзетимибом</b>, предпочтительно в
                        одной таблетке или капсуле<sup>7</sup>.
                      </li>
                      <li>
                        У пациентов с очень высоким риском и недостижением
                        целевого уровня ХС ЛНП на фоне максимально переносимых
                        доз статина в комбинации с эзетимибом рекомендовано
                        добавить <b>алирокумаб</b>, <b>эволокумаб</b> или
                        <b>инклисиран</b><sup>7</sup>.
                      </li>
                      <li>
                        В случае значительного повышения уровня ХС ЛНП у больных
                        очень высокого риска (выше 4,0 ммоль/л), рекомендуется
                        рассмотреть возможность инициального назначения статина
                        и эзетимиба<sup>7</sup>.
                      </li>
                      <li>
                        В случае значительного повышения уровня ХС ЛНП у больных
                        экстремального (наличие 2 и более сердечно-сосудистых
                        осложнений в течение 2 лет, несмотря на оптимальную
                        гиполипидемическую терапию и/или достигнутый уровень ХС
                        ЛНП &lt;1,4 ммоль/л) или очень высокого риска (выше
                        5,0 ммоль/л), рекомендуется рассмотреть возможность
                        инициального назначения
                        <b
                          >статина максимально переносимой
                          дозе+эзетимиб+ингибитора PCSK9</b
                        >: алирокумаба, эволокумаба или инклисирана<sup>7</sup>.
                      </li>
                    </ul>
                    <div class="mb-4">
                      <b
                        >Расчет степени снижения ХС ЛНП при различных вариантах
                        гиполипидемической терапии<sup>7,10</sup></b
                      >
                    </div>
                    <div class="full-screen mb-4 mx-auto">
                      <div
                        class="full-screen__trigger"
                        :data-coolbox="
                          require('@/assets/img/interactive-clinical-case/step20__table4.jpg')
                        "
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="8"
                            fill="#830051"
                          ></rect>
                          <path
                            d="M23 11H29V17"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M17 29H11V23"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M29 11L22 18"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11 29L18 22"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>

                      <img
                        :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table4.jpg')} 3x`"
                      />
                    </div>
                  </div>
                  <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                    <i
                      >Сокращения: ЛНП — липопротеиды низкой плотности, ХС —
                      холестерин.</i
                    >
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test21_1_1'"
                v-model="radioTabs[0]"
                :error="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Продолжить прием Омега-3 кислот этиловый эфир'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_1_2'"
                v-model="radioTabs[0]"
                :error="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Аторвастатин'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_1_3'"
                v-model="radioTabs[0]"
                :success="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Крестор'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_1_4'"
                v-model="radioTabs[0]"
                :error="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Эзетимиб'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_1_5'"
                v-model="radioTabs[0]"
                :error="radioTabs[0] !== null"
                :disabled="radioTabs[0] !== null"
                :label="'Эзетимиб + Статин'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${radioTabs[0] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] != null"
            >
              <div
                v-if="radioTabs[0] == 'test21_1_3'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/green_check2.png')
                  "
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs[0] !== 'test21_1_3'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/red_warning.png')
                  "
                />
                Неверный ответ!
              </div>
              <div>
                <div class="mb-4">
                  <video
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/8.mp4"
                    :poster="
                      require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                    "
                    controls
                  />
                </div>
                <div class="text-2 mb-4">
                  <div class="mb-4">
                    <b
                      >Оценка риска сердечно-сосудистых осложнений<sup
                        >7,10</sup
                      ></b
                    >
                  </div>
                  <div class="full-screen mb-4 mx-auto">
                    <div
                      class="full-screen__trigger"
                      :data-coolbox="
                        require('@/assets/img/interactive-clinical-case/step20__table1.jpg')
                      "
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>

                    <img
                      :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table1.jpg')} 2x`"
                    />
                  </div>
                  <div class="mb-2">
                    <b
                      >Выбор целевых значений ХС ЛНП с целью профилактики
                      ССО<sup>7,10</sup></b
                    >
                  </div>
                  <div class="full-screen mb-4 mx-auto">
                    <div
                      class="full-screen__trigger"
                      :data-coolbox="
                        require('@/assets/img/interactive-clinical-case/step20__table2.jpg')
                      "
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>

                    <img
                      :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table2.jpg')} 3x`"
                    />
                  </div>
                  <div class="mb-4">
                    <i>
                      Комментарий к клиническому случаю: риск ССО у пациентки
                      очень высокий, целевой уровень ХС ЛНП &lt;1,4 ммоль/л, ТГ
                      &lt;1,7 ммоль/л
                    </i>
                  </div>
                  <div class="full-screen mb-4 mx-auto">
                    <div
                      class="full-screen__trigger"
                      :data-coolbox="
                        require('@/assets/img/interactive-clinical-case/step20__table3.jpg')
                      "
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>

                    <img
                      :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table3.jpg')} 3x`"
                    />
                  </div>
                  <ul class="mb-4">
                    <li>
                      Всем пациентам с дислипидемией рекомендована терапия
                      <b>статином</b> в дозах, необходимых для достижения
                      целевого уровня ХС ЛНП. При непереносимости
                      высокоинтенсивной терапии статинов следует рассмотреть
                      снижение дозы препарата с последующей переоценкой
                      переносимости<sup>7</sup>.
                    </li>
                  </ul>
                  <div class="mb-2">
                    Розувастатин (Крестор) является одним из самых эффективных и
                    мощных препаратов в классе ингибиторов ГМГ-КоА-редуктазой.
                    Это обусловлено полярной метансульфонамидной группой,
                    которая обеспечивает уникальное взаимодействие с
                    ГМГ-КоА-редуктазой, образуя наибольшее количество
                    связывающих доменов<sup>11</sup>.
                  </div>
                  <div class="mb-2">
                    Период полувыведения розувастатина составляет около 19 часов
                    и является самым продолжительным среди препаратов своего
                    класса12. Такие факторы, как возраст, пол, время приема
                    препарата или пищи не оказывают значимого клинического
                    влияния на фармакокинетические свойства розувастатина13,14.
                    Абсолютная биодоступность розувастатина примерно равно 20% и
                    сопоставима с таковыми у аторвастатина, флувастатина,
                    правастатина и выше, чем у ловастатина и симвастатина (&lt;
                    5%)15. Гидрофильность розувастатина, в отличие от остальных
                    статинов, являющихся липофильными, определяет низкую
                    миотоксичность препарата в связи с незначительным
                    проникновением во внепеченочные ткани<sup>16</sup>.
                  </div>
                  <div class="mb-2">
                    В Российской Федерации Розувастатин (Крестор)
                    зарегистрирован и применяется в суточной дозе 5-40
                    мг<sup>17</sup>.
                  </div>
                  <div class="mb-2">
                    Процент снижения уровня ОХ, ХС ЛНП, ХС-не-ЛПВП под влиянием
                    розувастатина варьирует в зависимости от применяемой дозы
                    препарата<sup>17</sup>.
                  </div>
                  <div class="mb-4">
                    Внедрение в широкую клиническую практику розувастатина
                    основано на результатах исследований, выполненных в рамках
                    программы <b>GALAXY</b><sup>18-22</sup>.
                  </div>
                  <ul class="mb-8">
                    <li>
                      Пациентам с непереносимостью любой дозы статина
                      рекомендован прием <b>эзетимиба</b><sup>7</sup>.
                    </li>
                    <li>
                      Пациентам, не достигшим целевого уровня ХС ЛНП на фоне
                      максимально переносимых доз статинов, следует рассмотреть
                      возможность комбинированной терапии, в том числе
                      <b>статин с эзетимибом</b>, предпочтительно в одной
                      таблетке или капсуле<sup>7</sup>.
                    </li>
                    <li>
                      У пациентов с очень высоким риском и недостижением
                      целевого уровня ХС ЛНП на фоне максимально переносимых доз
                      статина в комбинации с эзетимибом рекомендовано добавить
                      <b>алирокумаб</b>, <b>эволокумаб</b> или <b>инклисиран</b
                      ><sup>7</sup>.
                    </li>
                    <li>
                      В случае значительного повышения уровня ХС ЛНП у больных
                      очень высокого риска (выше 4,0 ммоль/л), рекомендуется
                      рассмотреть возможность инициального назначения статина и
                      эзетимиба<sup>7</sup>.
                    </li>
                    <li>
                      В случае значительного повышения уровня ХС ЛНП у больных
                      экстремального (наличие 2 и более сердечно-сосудистых
                      осложнений в течение 2 лет, несмотря на оптимальную
                      гиполипидемическую терапию и/или достигнутый уровень ХС
                      ЛНП &lt;1,4 ммоль/л) или очень высокого риска (выше
                      5,0 ммоль/л), рекомендуется рассмотреть возможность
                      инициального назначения
                      <b
                        >статина максимально переносимой
                        дозе+эзетимиб+ингибитора PCSK9</b
                      >: алирокумаба, эволокумаба или инклисирана<sup>7</sup>.
                    </li>
                  </ul>
                  <div class="mb-4">
                    <b
                      >Расчет степени снижения ХС ЛНП при различных вариантах
                      гиполипидемической терапии<sup>7,10</sup></b
                    >
                  </div>
                  <div class="full-screen mb-4 mx-auto">
                    <div
                      class="full-screen__trigger"
                      :data-coolbox="
                        require('@/assets/img/interactive-clinical-case/step20__table4.jpg')
                      "
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>

                    <img
                      :srcset="`${require('@/assets/img/interactive-clinical-case/step20__table4.jpg')} 3x`"
                    />
                  </div>
                </div>
                <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                  <i
                    >Сокращения: ЛНП — липопротеиды низкой плотности, ХС —
                    холестерин.</i
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="icc__block-row" v-if="activeTab == 1">
          <div class="icc__block-text">
            <div class="icc__block-title">Какую дозу Крестора Вы выберите?</div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs[1] != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
                v-if="radioTabs[1] != null"
              >
                <div
                  v-if="radioTabs[1] == 'test21_2_5'"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/green_check2.png')
                    "
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs[1] !== 'test21_2_5'"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/red_warning.png')
                    "
                  />
                  Неверный ответ!
                </div>
                <div>
                  <div class="text-2 mb-4">
                    <div class="mb-2">
                      Суточная доза статина высокой интенсивности для снижения
                      ЛНП ≥ 50% (высокоинтенсивная терапией статинами)
                      подразумевается назначение аторвастатина в дозах 40–80
                      мг/сут или розувастатина в дозах 20–40 мг/сут.<sup>7</sup>
                    </div>
                    <div class="mb-2">
                      С учетом наличия у пациентки хронической болезни почек,
                      что является противопоказанием для дозы 40 мг (нарушение
                      функции почек средней степени тяжести, клиренс креатинина
                      менее 60 мл/мин) следует выбрать тактику старта Крестора с
                      5 мг с титрацией дозы до максимальной 20 мг в случае
                      хорошей переносимости <sup>17</sup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test21_2_1'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Старт с 5 мг и титрование дозы до 40 мг в случае хорошей переносимости для достижения целевых уровней ЛНП'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_2_2'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Старт с 10 мг и титрование дозы до максимально переносимых для достижения целевых ЛНП'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_2_3'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Старт с 15 мг и титрование дозы до 40 мг в случае хорошей переносимости для достижения целевых уровней ЛНП'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_2_4'"
                v-model="radioTabs[1]"
                :error="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Старт с 20 мг и титрование дозы до максимально переносимых для достижения целевых ЛНП'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test21_2_5'"
                v-model="radioTabs[1]"
                :success="radioTabs[1] !== null"
                :disabled="radioTabs[1] !== null"
                :label="'Старт с 5 мг и титрование дозы до 20 мг в случае хорошей переносимости для достижения целевых уровней ЛНП'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${radioTabs[1] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[1] != null"
            >
              <div
                v-if="radioTabs[1] == 'test21_2_5'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/green_check2.png')
                  "
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs[1] !== 'test21_2_5'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/red_warning.png')
                  "
                />
                Неверный ответ!
              </div>
              <div>
                <div class="text-2 mb-4">
                  <div class="mb-2">
                    Суточная доза статина высокой интенсивности для снижения ЛНП
                    ≥ 50% (высокоинтенсивная терапией статинами) подразумевается
                    назначение аторвастатина в дозах 40–80 мг/сут или
                    розувастатина в дозах 20–40 мг/сут.<sup>7</sup>
                  </div>
                  <div class="mb-2">
                    С учетом наличия у пациентки хронической болезни почек, что
                    является противопоказанием для дозы 40 мг (нарушение функции
                    почек средней степени тяжести, клиренс креатинина менее 60
                    мл/мин) следует выбрать тактику старта Крестора с 5 мг с
                    титрацией дозы до максимальной 20 мг в случае хорошей
                    переносимости <sup>17</sup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="prevStep"
        @nextStep="nextStep"
        :disableNext="radioTabs[activeTab] == null"
        :stepsCount="18"
        :activeStep="15"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
export default {
  name: "ICCStep20",
  components: {
    Steps,
    RadioButton,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: [null, null],
    activeTab: 0,
  }),
  methods: {
    answerTest() {
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
    prevStep() {
      if (this.activeTab > 0) {
        this.setActiveTab(this.activeTab - 1);
      } else {
        this.$emit("prevStep");
      }
    },
    nextStep() {
      if (this.activeTab == 0 && this.radioTabs[0] == "test21_1_3") {
        this.setActiveTab(this.activeTab + 1);
        return;
      }

      if (this.activeTab == 1) {
        this.$emit("nextStep");
        return;
      }

      this.$emit("nextStep");
    },
    setActiveTab(ind) {
      this.activeTab = ind;
    },
  },
  mounted() {
    for (let qInd in this.step.test.questions) {
      this.$set(
        this.radioTabs,
        qInd,
        this.step.test.questions[qInd].choices.find((a) => a.is_selected)
          ? this.step.test.questions[qInd].choices.find((a) => a.is_selected)
              .slug
          : null
      );
      if (
        qInd == 0 &&
        this.step.test.questions[qInd].choices.find((a) => a.is_selected) &&
        this.step.test.questions[qInd].choices.find((a) => a.is_selected)
          .slug == "test21_1_3"
      ) {
        this.setActiveTab(1);
      }
    }
  },
  watch: {
    radioTabs() {
      if (
        !this.step.test.questions[this.activeTab].is_answered &&
        this.radioTabs[this.activeTab]
      ) {
         let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[this.activeTab].slug,
            choices: [this.radioTabs[this.activeTab]],
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>